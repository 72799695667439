import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";

function FooterHomeOne() {
	return (
		<>
			<section className={`appie-footer-area appie-footer-area-dark`}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="copyright-text flex flex-col md:flex-row gap-4 justify-between">
								<p className="text-gray-200">Copyright © 2023 SmartEdit. All rights reserved.</p>

								<div className="flex gap-4">
									<Link to="/terms">
										<p className="text-gray-200">Terms of service</p>
									</Link>
									<Link to="/privacy">
										<p className="text-gray-200">Privacy policy</p>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default FooterHomeOne;
