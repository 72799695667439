import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "../api";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { FaCheck, FaTimes } from "react-icons/fa";
const stripePromise = loadStripe(
	"pk_live_51HAev3GHsKt4KgQEyyj9PPlt5HYwy70Si93jJCi9WbGNKb8OGepngilpO5Yl3lJTM0ULKsXyjXU2JGMj7C5wdkMu00X6S6OxNg"
);

const LoadingSpinner = () => (
	<div className="flex items-center justify-center py-1">
		<div
			style={{
				border: "2.75px solid #fff",
				borderRadius: "50%",
				borderTop: "2.75px solid #3498db",
				animation: "spin 2s linear infinite",
				width: "16px",
				height: "16px",
			}}
		></div>
	</div>
);

function PricingHomeOne({ user, fromPage, showUpgrade }) {
	const [toggleSwitch, setSwitchValue] = useState(false);
	const navigate = useNavigate();
	const [loading, setLoading] = useState("");

	const handleClick = async (plan, interval) => {
		setLoading(plan);
		if (user === null) {
			navigate("/login");
			return;
		}

		if (plan === "free") {
			navigate("/workspace");
			return;
		}

		try {
			// Créez une nouvelle session de paiement dans votre back-end

			const rewardfulReferral = Cookies.get("rewardful.referral");
			let affiliateId = null;
			if (rewardfulReferral) {
				affiliateId = JSON.parse(rewardfulReferral).id;
			}

			const response = await axios.post(
				"/stripe/checkout",
				{
					plan: plan,
					interval: interval,
					affiliateId: affiliateId,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			const session = response.data;

			const stripe = await stripePromise;
			const result = await stripe.redirectToCheckout({
				sessionId: session.id,
			});

			if (result.error) {
				// Si redirectToCheckout échoue en raison d'une erreur, affichez l'erreur dans votre console
				console.error(result.error.message);
			}
		} catch (error) {
			// Si une erreur se produit lors de l'appel à l'API, affichez l'erreur dans votre console
			console.error(
				"Une erreur s'est produite lors de la création de la session de paiement :",
				error
			);
		}
	};

	return (
		<>
			<section
				id="pricing"
				className={`appie-pricing-area ${
					fromPage ? "pt-16" : "pt-16"
				} pb-90 appie-pricing-area-dark`}
			>
				<div className="container">
					<div className="row mb-12">
						<div className="col-lg-12">
							<div className="appie-section-title text-center">
								{showUpgrade ? (
									<h3 className="appie-title">
										Upgrade for more <b>features</b>.
									</h3>
								) : (
									<>
										<h3 className="appie-title">
											Start creating, <b>today</b>.
										</h3>
										<p>Depending on your needs</p>
									</>
								)}
							</div>
							<ul
								className="list-inline text-center switch-toggler-list"
								role="tablist"
								id="switch-toggle-tab"
							>
								<li className={`month ${toggleSwitch ? "active" : ""}`}>
									<a>Monthly</a>
								</li>
								<li>
									<label
										onClick={() => setSwitchValue(!toggleSwitch)}
										className={`switch ${toggleSwitch ? "on" : "off"}`}
									>
										<span className="slider round" />
									</label>
								</li>
								<li className={`year ${toggleSwitch === false ? "active" : ""}`}>
									<a className="relative">
										<span className={`year ${toggleSwitch === false ? "active" : ""}`}>Yearly</span>
										<div className="absolute -left-24 top-[1.7rem]">
											<div className="flex items-center w-64">
												<span className="text-md">
													Save up to <span className="text-purple-400 font-semibold">30%</span>
												</span>
												<img
													className="pb-4 pl-2"
													src="https://uploads-ssl.webflow.com/64fb19236488892fc2c7c213/64fb3a6f8652da0df538ff8e_Arrow%20(6).svg"
												></img>
											</div>
										</div>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="tabed-content">
						{toggleSwitch ? (
							<div id="month" className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 gap-4">
								{/* Free edition */}
								<div className="wow animated fadeInLeft">
									<div className="pricing-one__single h-full flex flex-col">
										<div className="pricig-heading">
											<span className="block text-white text-xl">Free edition</span>
											<span className="text-gray-400 text-sm">
												Try and preview your first videos for free.
											</span>
											<div className="price-range mb-3">
												<div className="-mb-2">
													<sup>$</sup>
													<span>0</span>
													<p>/Mo</p>
												</div>
											</div>
											<button className="w-full opacity-0 bg-gray-900/60 text-center text-white font-semibold rounded-xl p-2 transition-colors hover:bg-gray-900/80">
												{loading !== "free" ? (
													<span className="text-white text-lg">Start Now</span>
												) : (
													<LoadingSpinner />
												)}
											</button>
										</div>
										<div className="pricig-body">
											<ul>
												<li className="flex items-center gap-2">
													<FaCheck /> 3 uploads
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Auto captions
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Trendy templates
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Auto B-roll
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Auto Zoom
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Call to action
												</li>
												<li className="flex items-center gap-2">
													<FaTimes /> No download
												</li>
											</ul>
										</div>
									</div>
								</div>
								{/* Basic edition */}
								<div className="wow animated fadeInRight">
									<div className="pricing-one__single h-full flex flex-col">
										<div className="pricig-heading">
											<span className="block text-white text-xl font-semibold">Basic edition</span>
											<span className="text-gray-400 text-sm">
												If you are starting in content creation.
											</span>
											<div className="price-range mb-3">
												<div className="-mb-2">
													<sup>$</sup>
													<span>12</span>
													<p>/Mo</p>
												</div>
											</div>
										</div>
										<button
											onClick={() => handleClick("basic", "monthly")}
											className="w-full bg-gray-900/60 text-center text-white font-semibold rounded-xl p-2 transition-colors hover:bg-gray-900/80"
										>
											{loading !== "basic" ? (
												<span className="text-white text-lg">Start Now</span>
											) : (
												<LoadingSpinner />
											)}
										</button>
										<div className="pricig-body">
											<ul>
												<li className="flex items-center gap-2">
													<FaCheck /> 30 shorts creation
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 300 MB / video
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Upload your own fonts
												</li>

												<li className="flex items-center gap-2">
													<FaCheck /> Full HD & 60 fps rendering
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Export to Premiere Pro & DaVinci Resolve
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 24/7 Live chat support
												</li>
											</ul>
										</div>
									</div>
								</div>
								{/* Expert edition */}
								<div className="wow animated fadeInRight">
									<div className="pricing-one__single h-full flex flex-col">
										<div className="pricig-heading">
											<span className="block text-white text-xl font-semibold">Expert edition</span>
											<span className="text-gray-400 text-sm">
												For professional creators or marketers.
											</span>
											{/* <div className="absolute top-1.5 right-1.5 bg-gray-900 rounded-lg p-1">
												<span className="text-green-500 text-xs font-semibold">
													LIMITED TIME: SAVE 44%
												</span>
											</div>
											<div className="price-range mb-3">
												<div className="-mb-2">
													<span className="mr-2 line-through">$69</span>
													<sup className="text-green-500">$</sup>
													<span className="text-green-500">39</span>
													<p>/Mo</p>
												</div>
											</div> */}
											<div className="price-range mb-3">
												<div className="-mb-2">
													<sup>$</sup>
													<span>18</span> <p>/Mo</p>
												</div>
											</div>
										</div>
										<button
											onClick={() => handleClick("expert", "monthly")}
											className="w-full bg-gray-900/60 text-center text-white font-semibold rounded-xl p-2 transition-colors hover:bg-gray-900/80"
										>
											{loading !== "expert" ? (
												<span className="text-white text-lg">Start Now</span>
											) : (
												<LoadingSpinner />
											)}
										</button>
										<div className="pricig-body">
											<ul>
												<li className="flex items-center gap-2">
													<FaCheck /> 100 shorts creation
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 400 MB / video
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Upload your own fonts
												</li>

												<li className="flex items-center gap-2">
													<FaCheck /> Full HD & 60 fps rendering
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Export to Premiere Pro & DaVinci Resolve
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 24/7 Live chat support
												</li>
											</ul>
										</div>
									</div>
								</div>{" "}
								{/* Pro edition */}
								<div className="wow animated fadeInUp shadow-[0_0_0_2px] shadow-purple-500 rounded">
									<div className="pricing-one__single center h-full flex flex-col">
										<div className="pricig-heading">
											<span className="block text-white text-xl font-semibold">Pro edition</span>
											<span className="text-gray-400 text-sm">
												To grow your audience and business.
											</span>
											<div className="price-range mb-3">
												<div className="-mb-2">
													<sup>$</sup>
													<span>30</span>
													<p>/Mo</p>
												</div>
											</div>
										</div>
										<button
											onClick={() => handleClick("pro", "monthly")}
											className="w-full bg-gray-900/60 text-center text-white font-semibold rounded-xl p-2 transition-colors hover:bg-gray-900/80"
										>
											{loading !== "pro" ? (
												<span className="text-white text-lg">Start Now</span>
											) : (
												<LoadingSpinner />
											)}
										</button>
										<div className="pricig-body">
											<ul>
												<li className="flex items-center gap-2">
													<FaCheck /> Unlimited shorts creation
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 600 MB / video
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Upload your own fonts
												</li>

												<li className="flex items-center gap-2">
													<FaCheck /> Full HD & 60 fps rendering
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Export to Premiere Pro & DaVinci Resolve
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 24/7 Live chat support
												</li>
											</ul>
										</div>
										<div className="pricing-rebon">
											<span>Most popular</span>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div id="year" className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 gap-4">
								{/* Free edition Annuelle */}
								<div className="wow animated fadeInLeft">
									<div className="pricing-one__single h-full flex flex-col">
										<div className="pricig-heading">
											<span className="block text-white text-xl">Free edition</span>
											<span className="text-gray-400 text-sm">
												Try and preview your first videos for free.
											</span>
											<div className="price-range mb-3">
												<div className="-mb-2">
													<sup>$</sup>
													<span>0</span>
													<p>/Mo</p>
												</div>
											</div>
											<button className="w-full opacity-0 bg-gray-900/60 text-center text-white font-semibold rounded-xl p-2 transition-colors hover:bg-gray-900/80">
												<span className="text-white text-lg">Start Now</span>
											</button>
										</div>
										<div className="pricig-body">
											<ul>
												<li className="flex items-center gap-2">
													<FaCheck /> 3 uploads
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Auto captions
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Trendy templates
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Auto B-roll
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Auto Zoom
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Call to action
												</li>
												<li className="flex items-center gap-2">
													<FaTimes /> No download
												</li>
											</ul>
										</div>
									</div>
								</div>
								{/* Basic edition Annuelle */}
								<div className="wow animated fadeInRight">
									<div className="pricing-one__single h-full flex flex-col">
										<div className="pricig-heading">
											<span className="block text-white text-xl font-semibold">Basic edition</span>
											<span className="text-gray-400 text-sm">
												If you are starting in content creation.
											</span>
											<div className="price-range mb-3">
												<div className="-mb-2">
													<sup>$</sup>
													<span>8</span> <p>/Mo</p>
												</div>
											</div>
										</div>
										<button
											onClick={() => handleClick("basic", "annual")}
											className="w-full bg-gray-900/60 text-center text-white font-semibold rounded-xl p-2 transition-colors hover:bg-gray-900/80"
										>
											{loading !== "basic" ? (
												<span className="text-white text-lg">Start Now</span>
											) : (
												<LoadingSpinner />
											)}
										</button>
										<div className="pricig-body">
											<ul>
												<li className="flex items-center gap-2">
													<FaCheck /> 30 shorts creation
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 300 MB / video
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Upload your own fonts
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Full HD & 60 fps rendering
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Export to Premiere Pro & DaVinci Resolve
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 24/7 Live chat support
												</li>
											</ul>
										</div>
									</div>
								</div>
								{/* Expert edition Annuelle */}
								<div className="wow animated fadeInRight">
									<div className="pricing-one__single h-full flex flex-col">
										<div className="pricig-heading">
											<span className="block text-white text-xl font-semibold">Expert edition</span>
											<span className="text-gray-400 text-sm">
												For professional creators or marketers.
											</span>
											{/* <div className="absolute top-1.5 right-1.5 bg-gray-900 rounded-lg p-1">
												<span className="text-green-500 text-xs font-semibold">
													LIMITED TIME: SAVE 58%
												</span>
											</div>
											<div className="price-range mb-3">
												<div className="-mb-2">
													<span className="mr-2 line-through">$69</span>
													<sup className="text-green-500">$</sup>
													<span className="text-green-500">29</span> <p>/Mo</p>
												</div>
											</div> */}
											<div className="price-range mb-3">
												<div className="-mb-2">
													<sup>$</sup>
													<span>13</span> <p>/Mo</p>
												</div>
											</div>
										</div>
										<button
											onClick={() => handleClick("expert", "annual")}
											className="w-full bg-gray-900/60 text-center text-white font-semibold rounded-xl p-2 transition-colors hover:bg-gray-900/80"
										>
											{loading !== "expert" ? (
												<span className="text-white text-lg">Start Now</span>
											) : (
												<LoadingSpinner />
											)}
										</button>
										<div className="pricig-body">
											<ul>
												<li className="flex items-center gap-2">
													<FaCheck /> 100 shorts creation
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 400 MB / video
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Upload your own fonts
												</li>

												<li className="flex items-center gap-2">
													<FaCheck /> Full HD & 60 fps rendering
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Export to Premiere Pro & DaVinci Resolve
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 24/7 Live chat support
												</li>
											</ul>
										</div>
									</div>
								</div>
								{/* Pro edition Annuelle */}
								<div className="wow animated fadeInUp shadow-[0_0_0_2px] shadow-purple-500 rounded">
									<div className="pricing-one__single center h-full flex flex-col">
										<div className="pricig-heading">
											<span className="block text-white text-xl font-semibold">Pro edition</span>
											<span className="text-gray-400 text-sm">
												To grow your audience and business.
											</span>
											<div className="price-range mb-3">
												<div className="-mb-2">
													<sup>$</sup>
													<span>21</span> <p>/Mo</p>
												</div>
											</div>
										</div>
										<button
											onClick={() => handleClick("pro", "annual")}
											className="w-full bg-gray-900/60 text-center text-white font-semibold rounded-xl p-2 transition-colors hover:bg-gray-900/80"
										>
											{loading !== "pro" ? (
												<span className="text-white text-lg">Start Now</span>
											) : (
												<LoadingSpinner />
											)}
										</button>
										<div className="pricig-body">
											<ul>
												<li className="flex items-center gap-2">
													<FaCheck /> Unlimited shorts creation
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 600 MB / video
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Upload your own fonts
												</li>

												<li className="flex items-center gap-2">
													<FaCheck /> Full HD & 60 fps rendering
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> Export to Premiere Pro & DaVinci Resolve
												</li>
												<li className="flex items-center gap-2">
													<FaCheck /> 24/7 Live chat support
												</li>
											</ul>
										</div>
										<div className="pricing-rebon">
											<span>Most popular</span>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</section>
		</>
	);
}

export default PricingHomeOne;
